<template>
  <div class="content-box-padding">
    <div class="sub-title">技术手段</div>
    <div class="line-text" style="margin-top: 20px">
      研究院参与了数次国家重大活动的空气质量保障，从会前研究、会期保障、到会后工作总结评估，从预测过程到诊断评估等，全方位参与会议空气质量保障服务。表现优异，得到环保部和地方部门高度认可，获得相关荣誉表彰。
    </div>
    <img
      src="~assets\image\business\重大会议活动空气质量保障服务1.png"
      alt="重大会议活动空气质量保障服务1"
      style="margin: 50px auto 30px auto; width: 80%"
    /><img
      src="~assets\image\business\重大会议活动空气质量保障服务2.png"
      alt="重大会议活动空气质量保障服务2"
      style="margin: 30px auto 0px auto; width: 80%"
    />
  </div>
</template>
